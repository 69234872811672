export const Products = [
  {
    title: "VOUCHA SaaS",
    description: "A custom-built gift card management system with dedicated admin & customer portal. Easily create, distribute, and track both physical and digital gift cards to engage customers and drive sales",
    href: "http://lucas.voucha.nexzap.com"
  },
  {
    title: "RAGBOT SaaS",
    description:"A cutting-edge, LLM-based Retrieval-Augmented Generation (RAG) model designed to help companies organize and retrieve information efficiently. With RAGBOT, businesses can leverage AI for intelligent document search and processing.",
    href: "http://lucas.chatbot.nexzap.com"
  },
  
  {
    title: "Web Applications",
    description: "We specialize in creating tailored software applications that meet your business's unique needs. From automation to full-scale platforms, we deliver innovative solutions that scale with your growth.",
  },
  {
    title: "SaaS Products",
    description: "Sell, redeem, and recharge gift cards both online and through POS terminals.Sell, redeem, and recharge gift cards both online and through POS terminals.",
  },
];
  
  
  export const projects = [

    {
      id: 1,
      title: "Smart Blind Stick",
      date: "Sept 2020 - Oct 2022",
      description:
        "This innovative assistive technology, crafted for individuals with visual impairments, employs advanced ultrasonic sensors and the Arduino UNO platform. It provides precise obstacle detection within a 5-meter radius, accompanied by an audible buzzer signal and the added feature of creating vibrations. This dual-alert system enhances user safety and confidence in navigating their surroundings, offering a comprehensive solution for individuals with visual challenges.",
      image:null,
      tags: ["Arduino UNO", "Ultrasonic Sensor","Buzzer", "Code in C++"],
      category: "Iot",
    },
    
  ];
  
  